.cost-center-popover-overlay .ant-popover-inner-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}
.cost-center-popover-overlay .ant-popover-inner {
  padding-bottom: 4px;
}
.cost-center-popover-overlay .ant-tag {
  margin-bottom: 8px;
}
