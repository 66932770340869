* {
  font-family: 'Outfit', sans-serif;
}

.App {
  overflow-x: hidden;
}

.content-container {
  overflow: auto;
  position: relative;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.p-relative {
  position: relative;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.justify-space-between {
  justify-content: space-between;
}

.align-content-start {
  align-content: start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.d-flex-child > * {
  display: flex;
}

.flex-child-column-center-start > * {
  flex-direction: column !important;
  align-items: center !important;
  justify-content: start !important;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.custom-label {
  height: 16px;
  padding: 2px 4px !important;
}

.custom-label p {
  margin: 0;
  padding: 2px 0 !important;
}

.main-font-color {
  color: #00AB92;
}

.secondary-font-color {
  color: #0D175C;
}

.select-item {
  width: 100%;
  font-size: 14px;
}

.select-item.active {
  color: #0a145a;
}

.select-item.active input + div {
  background: #0a145a;
  border-color: #0a145a;
}

.primary-outline {
  border-color: #66C0DD;
  color: #66C0DD;
}

.primary-outline:hover {
  border-color: #1688AE;
  color: #1688AE;
}

.primary-outline:active,
.primary-outline:focus {
  border-color: #1688AE;
  color: #1688AE;
}

.ant-btn:not(.ant-btn-text):not(.ant-btn-link):not(.ant-btn-icon-only):active,
.ant-btn:not(.ant-btn-text):not(.ant-btn-link):not(.ant-btn-icon-only):focus,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  box-shadow: 0 0 2px 2px rgba(64, 176, 213, 0.25);
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #66C0DD;
  color: #66C0DD;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus {
  border-color: #07132B;
  color: #07132B;
}

.error-message {
  font-size: 12px;
  color: rgb(255,59,48);
  padding-top: 4px;
}

.error-message ul {
  padding: 0.25rem 0 0.5rem 1.5rem;
  margin: 0;
}

.error-landing {
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 4px;
  line-height: 1.15rem;
}

.landing-help-text {
  min-height: 22px;
}

.landing-help-text .error-landing {
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 0;
}

.pr-20 {
  padding-right: 20px;
}

.table-head-pr-20 + div {
  padding-right: 20px;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-20 {
  padding-top: 20px;
}

.px-22 {
  padding-left: 22px;
  padding-right: 22px;
}

.disabled {
  opacity: 0.4;
  cursor: unset;
  pointer-events: none;
}

.windowScroll {
  height: auto !important;
  max-height: 210px;
}

path {
  fill-rule: evenodd !important;
  clip-rule: evenodd !important;
}

.pointer:hover {
  cursor: pointer;
}

.ml-10 {
  margin-left: 10px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 10px;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.m-auto {
  margin: auto;
}

.w-40 {
  width: 40%;
}

.w-100 {
  width: 100%;
}

.d-none {
  display: none !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-none {
  text-transform: none !important;
}

.form-step-container {
  height: auto;
  opacity: 1;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}

.form-step-container.form-d-none {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0ms 300ms, opacity 300ms 0ms;
  padding: 0;
}

.break-word-all * {
  word-break: break-word;
}

.page-container {
  padding-left: 22px;
  padding-right: 22px;
}

.item-custom-select {
  height: 32px;
  border-radius: 0 !important;
}

.blur-bg {
  backdrop-filter: blur(5px);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.label-with-information > div > div {
  width: calc(100% - 16px);
}

/* Custom scrollbar */
*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
	border-radius: 6px;
}

*::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	background-color: #D8D8D8;
}

/* Disable arrow in number input */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

/* Animation keyframe */
.fade-in {
  animation: fadeIn ease-in .1s;
}
@keyframes fadeIn {
  0% { opacity: 1}
  50% { opacity: 1; }
  100% { opacity: 0; }
}

input:focus,
div[role="button"],
div[role="button"]:focus {
  border-width: 1px !important;
}

.flag-count {
  background-color: transparent !important;
  height: unset;
}

.flag-count > p {
  font-size: 14px;
  color: #191927;
  font-weight: normal;
  opacity: 0.5;
}

.select-search {
  border-color: #0A145A !important;
  box-shadow: unset !important;
}

.select-search:hover {
  border-color: #0A145A;
}

.bottom-arrow {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 100%;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

.left-arrow {
  width: 10px;
  height: 10px;
  position: absolute;
  top: calc(50% - 5px);
  left: 0;
  overflow: hidden;
  transform: translate(-100%, 50%) rotate(90deg);
}

.right-arrow {
  width: 10px;
  height: 10px;
  position: absolute;
  top: calc(50% - 5px);
  left: 100%;
  overflow: hidden;
  transform: translateY(50%) rotate(-90deg);
}

.bottom-arrow::after,
.left-arrow::after,
.right-arrow::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: rgba(58, 57, 73, 0.96);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 0;
  left: 50%;
}

.bar::after {
  content: ''
}

.paid-legend {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 0.5rem;
}

.due-legend {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 0.5rem;
  background-size: 11px 11px;
  background-image: linear-gradient(135deg, #000 25%, #fff 25%, #fff 50%, #000 50%, #000 75%, #fff 75%, #fff);
}

/* Radio buttons */
.radio-buttons > div {
  display: flex;
}

.radio-buttons.d-block > div {
  display: block;
}

.radio-buttons.d-block > div label {
  flex-wrap: wrap;
}

.radio-buttons > div label:not(:last-of-type) {
  margin-right: 30px;
}

.radio-buttons > div label > div {
  height: 17px;
  margin-right: 10px;
}

.radio-buttons > div label > div > div {
  width: 16px;
  height: 16px;
}

.radio-buttons > div label span {
  font-size: 14px;
  letter-spacing: 0.18px;
}
.radio-buttons > div label input:checked ~ div:after {
  width: 8px;
  height: 8px;
}

.choose-person-select span {
  font-family: 'Mulish', sans-serif;
}

.choose-person-select input[type=radio] ~ div,
.choose-person-select input[type=radio] ~ div:after {
  width: 20px;
  height: 18px;
}

.expense-modal {
  width: 512px;
  margin-left: auto;
}

.expense-modal div[aria-hidden="true"] {
  display: none;
}

.expense-modal .modal-spinner {
  background-color: rgba(255, 255, 255, 0.5);
}

.row-action {
  opacity: 1;
  transition: .3s ease;
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  z-index: 2;
}

.row-date {
  opacity: 1;
  transition: .3s ease;
}

.hidden-row-action {
  opacity: 0 !important;
  pointer-events: none;
}

.white-bordered-scrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 5px;
	background-color: transparent;
}

.white-bordered-scrollbar::-webkit-scrollbar
{
	width: 6px;
	background-color: transparent;
}

.white-bordered-scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
	background-color: #dcdbdb;
}

@keyframes spinner-load-bounce {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tree-list-icon {
  padding-right: 6px;
}

.tree-list-icon > * {
  height: 12px;
  width: 12px;
}

.pre-image {
  margin-right: 12px;
}

.drag-placeholder {
  position: absolute;
  border-radius: 3px;
  background-color: #00AB92;
  width: calc(100% - (22px * 2));
  left: 22px !important;
  height: 3px;
}
.drag-placeholder::before {
  left: -8px;
}
.drag-placeholder::after {
  right: -8px;
}
.drag-placeholder::before,
.drag-placeholder::after {
  content: ' ';
  position: absolute;
  width: 10px;
  height: 10px;
  border: 3px solid #00AB92;
  transform: translateY(-7px);
  background-color: #fff;
  border-radius: 50%;
}

.error-color {
  color: rgb(255,59,48);
}

.unread-counter {
  display: block;
  padding: 0 !important;
  width: 12px;
  height: 12px;
  min-width: 12px !important;
  top: 0;
  border-radius: 50% !important;
  background-color: #FF0000 !important;
  border: 2px solid #000;
}

.row-reverse {
  flex-direction: row-reverse;
}

.circle-bg-image {
  width: 65px;
  height: 65px;
  background-color: rgba(0, 171, 146, 0.39);
  border-radius: 50%;
  position: relative;
  margin-top: 14px;
  margin-bottom: 18px;
}

.circle-bg-image img {
  max-height: 60px;
  position: absolute;
  left: 16px;
  bottom: 14px;
}

.circle-bg-image::after {
  content: ' ';
}

.personal-form [role="button"][name="country"] > div:nth-of-type(2) > div:nth-of-type(2) {
  top: unset;
  bottom: 44px;
  transition: unset;
  display: flex;
  flex-direction: column-reverse;
  box-shadow: 0 -1px 6px 0 rgb(25 25 39 / 25%);
}

.tax-residence-select > div:first-of-type > div {
  flex: unset;
}

.tax-residence-select + div {
  transition: 0.2s ease-in;
}

.tax-residence-select + div label {
  padding-top: 15px;
}

/*.legal-form-select .item-custom-select {*/
/*  height: 38px;*/
/*}*/

.legal-form-select .select-item-label {
  max-width: 230px;
}

.legal-form-select .item-custom-select > div {
  flex: unset;
  line-height: 16px;
}

.icon-person-option {
  position: relative;
  transition: 0.2s ease-in;
}

.icon-person-option:before {
  position: absolute;
  content: ' ';
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 50%;
  width: 100%;
  transition: 0.2s ease-in;
}

.empty-select-items-list {
  min-height: 120px !important;
  padding: 10px;
}

.empty-select-items-list i > * {
  width: 32px;
  height: 32px;
}

.empty-select-items-list p {
  margin-bottom: 0;
  font-size: 14px;
}

.white-rows-bordered-table .table-row:not(:last-of-type) .row-container:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 20px;
  width: calc(100% - (20px * 2));
  border-bottom: 1px solid #E9E9E9;
}

.white-rows-bordered-table.transactions .table-row:not(:last-of-type) .row-container:after {
  border-bottom-width: 2px;
  bottom: -1px;
}

#h1-tooltip-portal > div {
  max-width: 220px;
  width: auto;
}

.transition-2s-ease {
  transition: .2s ease;
}

.page-modal-presentation,
.page-modal-presentation > div[aria-hidden="true"] {
  margin-left: 225px;
}

@media screen and (max-height: 762px) {
  .card-company-details-contact-form [role="button"][name="country"] > div:nth-of-type(2) > div:nth-of-type(2) {
    top: unset;
    bottom: 44px;
    transition: unset;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: 0 -1px 6px 0 rgb(25 25 39 / 25%);
  }
}

@media screen and (max-height: 820px) {
  .card-person-address-form [role="button"][name="country"] > div:nth-of-type(2) > div:nth-of-type(2) {
    top: unset;
    bottom: 44px;
    transition: unset;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: 0 -1px 6px 0 rgb(25 25 39 / 25%);
  }
}

@media screen and (max-width: 1200px) {
  .flex-column-md {
    flex-direction: column;
  }
}

.primary-text-btn {
  color: #515FFF;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    color: #7A8AFF;
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    color: #3B42D9;
  }
}

.green-btn-outlined {
  &:not(:disabled):not(.ant-btn-disabled):hover {
    border-color: #22D495;
    color: #22D495;
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    border-color: #00A173;
    color: #00A173;
  }
}

/* Start pagination styles */
.ant-table-wrapper .ant-table-pagination .ant-pagination-item,
.ant-table-wrapper .ant-table-pagination .ant-pagination-jump-next,
.ant-table-wrapper .ant-table-pagination .ant-pagination-jump-prev {
    display: none;
}

.ant-table-wrapper .ant-table-pagination .ant-pagination-prev,
.ant-table-wrapper .ant-table-pagination .ant-pagination-next {
  background-color: #d9d9d9;
  border-radius: 3px;
  transition: .2s ease;
}

.ant-table-wrapper .ant-table-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-table-wrapper .ant-table-pagination .ant-pagination-next .ant-pagination-item-link {
  border-radius: 3px;
  color: #262626;
  font-size: 13px;
  transition: .2s ease;
}

.ant-table-wrapper .ant-table-pagination .ant-pagination-prev.ant-pagination-disabled,
.ant-table-wrapper .ant-table-pagination .ant-pagination-next.ant-pagination-disabled {
  background-color: #F0F0F0;
}

.ant-table-wrapper .ant-table-pagination .ant-pagination-prev.ant-pagination-disabled .ant-pagination-item-link,
.ant-table-wrapper .ant-table-pagination .ant-pagination-next.ant-pagination-disabled .ant-pagination-item-link {
  color: #595959;
}

.ant-table-wrapper .ant-table-pagination .ant-pagination-prev:not(.ant-pagination-disabled):hover,
.ant-table-wrapper .ant-table-pagination .ant-pagination-next:not(.ant-pagination-disabled):hover {
  background-color: #B3DFEE;
  color: #40B0D5;
}

.ant-table-wrapper .ant-table-pagination .ant-pagination-prev:not(.ant-pagination-disabled):hover .ant-pagination-item-link,
.ant-table-wrapper .ant-table-pagination .ant-pagination-next:not(.ant-pagination-disabled):hover .ant-pagination-item-link {
  color: #07132B;
}

.ant-table-wrapper .ant-table-pagination .ant-pagination-prev:not(.ant-pagination-disabled):active,
.ant-table-wrapper .ant-table-pagination .ant-pagination-next:not(.ant-pagination-disabled):active,
.ant-table-wrapper .ant-table-pagination .ant-pagination-prev:not(.ant-pagination-disabled):focus,
.ant-table-wrapper .ant-table-pagination .ant-pagination-next:not(.ant-pagination-disabled):focus {
  background-color: #8CD0E6;
}

.ant-table-wrapper .ant-table-pagination .ant-pagination-prev:not(.ant-pagination-disabled):active .ant-pagination-item-link,
.ant-table-wrapper .ant-table-pagination .ant-pagination-next:not(.ant-pagination-disabled):active .ant-pagination-item-link,
.ant-table-wrapper .ant-table-pagination .ant-pagination-prev:not(.ant-pagination-disabled):focus .ant-pagination-item-link,
.ant-table-wrapper .ant-table-pagination .ant-pagination-next:not(.ant-pagination-disabled):focus .ant-pagination-item-link {
  color: #F0F4FF;
}

/* End pagination styles */

.ant-btn.large-btn {
  height: 56px;
}

.ReactCollapse--collapse {
  transition: .3s ease-in-out;
}

.ant-select-dropdown .ant-select-item-option-content {
  display: flex;
}

.full-width-spin {
  position: absolute;
  height: 100%;
  width: 100%;
}

.ant-avatar > img {
  object-fit: unset;
}

textarea.ant-input,
input.ant-input,
input.ant-input-number-input,
.ant-tag,
.dropdown-searched-content .ant-typography {
  font-family: 'Outfit', sans-serif;
}

#app-content-container .ant-form-item .ant-input.ant-input-lg:not(.ant-dropdown-trigger),
.ant-modal .ant-form-item .ant-input.ant-input-lg:not(.ant-dropdown-trigger),
.ant-drawer .ant-form-item .ant-input.ant-input-lg:not(.ant-dropdown-trigger),
.external-page-with-form .ant-form-item .ant-input.ant-input-lg:not(.ant-dropdown-trigger) {
  font-size: 14px;
  height: 38px;
  padding: 7px 12px;
}

#app-content-container .ant-form-item .ant-dropdown-trigger .ant-input.ant-input-lg,
#app-content-container .ant-form-item .ant-input-affix-wrapper .ant-input.ant-input-lg,
.amt-modal .ant-form-item .ant-dropdown-trigger .ant-input.ant-input-lg,
.amt-modal .ant-form-item .ant-input-affix-wrapper .ant-input.ant-input-lg,
.ant-drawer .ant-form-item .ant-dropdown-trigger .ant-input.ant-input-lg,
.ant-drawer .ant-form-item .ant-input-affix-wrapper .ant-input.ant-input-lg,
.external-page-with-form .ant-form-item .ant-dropdown-trigger .ant-input.ant-input-lg,
.external-page-with-form .ant-form-item .ant-input-affix-wrapper .ant-input.ant-input-lg {
  height: unset;
  padding: 0;
}

#app-content-container .ant-input-affix-wrapper-lg:not(.ant-dropdown-trigger),
.ant-modal .ant-input-affix-wrapper-lg:not(.ant-dropdown-trigger),
.ant-drawer .ant-input-affix-wrapper-lg:not(.ant-dropdown-trigger),
.external-page-with-form .ant-input-affix-wrapper-lg:not(.ant-dropdown-trigger) {
  font-size: 14px;
  line-height: 1.5rem;
  height: 38px;
  padding: 7px 12px;
}

#app-content-container .ant-input-affix-wrapper-lg.ant-dropdown-trigger,
.ant-modal .ant-input-affix-wrapper-lg.ant-dropdown-trigger,
.ant-drawer .ant-input-affix-wrapper-lg.ant-dropdown-trigger,
.external-page-with-form .ant-input-affix-wrapper-lg.ant-dropdown-trigger {
  font-size: 14px;
  height: 38px;
}

#app-content-container .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
.ant-modal .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
.ant-drawer .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
.external-page-with-form .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px;
}

#app-content-container .ant-form-item .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
#app-content-container .ant-form-item .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
.ant-modal .ant-form-item .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-modal .ant-form-item .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
.ant-drawer .ant-form-item .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-drawer .ant-form-item .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
.external-page-with-form .ant-form-item .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.external-page-with-form .ant-form-item .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 36px;
}

#app-content-container .ant-form-item .ant-select-single.ant-select-lg,
#app-content-container .ant-form-item .ant-select-single.ant-select-lg .ant-select-selector,
.ant-modal .ant-form-item .ant-select-single.ant-select-lg,
.ant-modal .ant-form-item .ant-select-single.ant-select-lg .ant-select-selector,
.ant-drawer .ant-form-item .ant-select-single.ant-select-lg,
.ant-drawer .ant-form-item .ant-select-single.ant-select-lg .ant-select-selector,
.external-page-with-form .ant-form-item .ant-select-single.ant-select-lg,
.external-page-with-form .ant-form-item .ant-select-single.ant-select-lg .ant-select-selector,
#app-content-container .ant-form-item .ant-select-multiple.ant-select-lg,
.ant-modal .ant-form-item .ant-select-multiple.ant-select-lg,
.ant-drawer .ant-form-item .ant-select-multiple.ant-select-lg,
.external-page-with-form .ant-select-multiple.ant-select-lg,
#app-content-container .ant-select.ant-select-lg,
.ant-modal .ant-select.ant-select-lg,
.ant-drawer .ant-select.ant-select-lg,
.external-page-with-form .ant-select.ant-select-lg,
#app-content-container .ant-select.ant-select-lg .ant-select-selector,
.ant-modal .ant-select.ant-select-lg .ant-select-selector,
.ant-drawer .ant-select.ant-select-lg .ant-select-selector,
.external-page-with-form .ant-select.ant-select-lg .ant-select-selector {
  font-size: 14px;
}

#app-content-container .ant-form-item .ant-select-multiple.ant-select-lg .ant-select-selector,
.ant-modal .ant-form-item .ant-select-multiple.ant-select-lg .ant-select-selector,
.ant-drawer .ant-form-item .ant-select-multiple.ant-select-lg .ant-select-selector,
.external-page-with-form .ant-form-item .ant-select-multiple.ant-select-lg .ant-select-selector {
  padding: 0 4px;
}

#app-content-container .ant-input-number-lg input.ant-input-number-input,
#app-content-container .ant-picker-large .ant-picker-input > input,
.ant-modal .ant-input-number-lg input.ant-input-number-input,
.ant-modal .ant-picker-large .ant-picker-input > input,
.ant-drawer .ant-input-number-lg input.ant-input-number-input,
.ant-drawer .ant-picker-large .ant-picker-input > input,
.external-page-with-form .ant-input-number-lg input.ant-input-number-input,
.external-page-with-form .ant-picker-large .ant-picker-input > input {
  font-size: 14px;
  height: unset;
}

#app-content-container .ant-input-number-input-wrap,
#app-content-container .ant-input-number-lg input.ant-input-number-input,
.ant-modal .ant-input-number-input-wrap,
.ant-modal .ant-input-number-lg input.ant-input-number-input,
.ant-drawer .ant-input-number-input-wrap,
.ant-drawer .ant-input-number-lg input.ant-input-number-input,
.external-page-with-form .ant-input-number-input-wrap,
.external-page-with-form .ant-input-number-lg input.ant-input-number-input{
  height: 36px;
}

#app-content-container .ant-picker-large,
.ant-modal .ant-picker-large,
.ant-drawer .ant-picker-large,
.external-page-with-form .ant-picker-large {
  height: 38px;
  padding: 5px 11px;
}

.ant-table-thead > tr > th,
.ant-table-thead > tr > td {
  text-wrap: nowrap;
}
