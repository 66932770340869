.overview-dropdown-overlay .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #262626;
  font-size: 14px;
  line-height: 22px;
}

.overview-dropdown-overlay.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  background-color: #E6FFF3;
  color: #262626;
  font-weight: 500;
}

.overview-dropdown-overlay.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: #E6FFF3;
}