.image-preview-tooltip-overlay.single {
  max-width: 520px;
}

.image-preview-tooltip-overlay:not(.single) {
  inset: 50% 0 0 calc(50% - 600px) !important;
  transform: translate(-50%, -50%) !important;
}

@media (max-width: 1100px) {
  .image-preview-tooltip-overlay:not(.single) {
    inset: 50% 0 0 calc(50% - 320px) !important;
  }
}

.image-preview-tooltip-overlay .ant-tooltip-inner {
  border: 2px solid #A3B1FF;
  background-color: #fff;
  color: #262626;
  width: 460px;
  height: auto;
}

.image-preview-tooltip-overlay .ant-tooltip-inner > * {
  max-height: 560px;
  width: max-content;
  max-width: 440px;
  overflow: hidden;
}

.image-preview-tooltip-overlay.single .ant-tooltip-inner {
  width: max-content;
  height: auto;
}

.image-preview-tooltip-overlay.single .ant-tooltip-inner > * {
  max-height: 480px;
  width: max-content;
  max-width: 460px;
}