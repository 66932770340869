.tag-select-overlay.ant-dropdown-placement-top .dropdown-searched-content {
  margin-bottom: 44px;
}

.tag-select-overlay.ant-dropdown-placement-top .search {
  bottom: 4px;
}

.tag-select-overlay.ant-dropdown-placement-bottom .dropdown-searched-content {
  margin-top: 44px;
}

.dropdown-searched-content .ant-menu {
  border-inline-end: none !important;
}

.dropdown-searched-content .ant-menu li {
  margin-inline: 0;
  width: 100%;
}
