.expanded-subscription-row-dropdown .ant-dropdown-menu-item.danger {
  color: #FF4D4F;
}
.expanded-subscription-row-dropdown .ant-dropdown-menu-item.danger:hover,
.expanded-subscription-row-dropdown .ant-dropdown-menu-item.danger:active {
  color: #e31a1c;
}
.expanded-subscription-row-dropdown .ant-dropdown-menu-item.primary {
  color: #515FFF;
}
.expanded-subscription-row-dropdown .ant-dropdown-menu-item.primary:hover,
.expanded-subscription-row-dropdown .ant-dropdown-menu-item.primary:active {
  color: #272AB3;
}